
import { Component, Prop, Emit, Watch, Vue } from 'vue-property-decorator';
import { RawLocation } from 'vue-router';
import { TopAppBar } from '@gsk-tech/gsk-top-app-bar/gsk-top-app-bar';
import MaxWidth from '@/components/MaxWidth.vue';

@Component({
  components: {
    MaxWidth,
  },
})
export default class FullScreenForm extends Vue {
  @Prop(Object) readonly closeRoute!: RawLocation;
  @Prop(Boolean) readonly hideBack!: boolean;
  @Prop(Boolean) readonly closed!: boolean;
  @Prop(Boolean) readonly hideControls!: boolean;
  @Prop(Boolean) readonly noCenter!: boolean;
  @Prop(Boolean) readonly loading!: boolean;
  @Prop({ type: String, default: '' }) readonly title!: string;
  @Prop({ type: Boolean, default: false }) readonly noMaxWidth?: boolean;
  @Prop({ type: Boolean, default: false }) readonly noFlexCenter?: boolean;

  mounted() {
    Vue.nextTick(() => {
      const bar = document.querySelector('#fs-app-bar') as TopAppBar;
      bar.scrollTarget = document.querySelector('#fs-layout-main') as HTMLElement;
    });
  }

  @Watch('closed')
  onClose(isClosed: boolean) {
    if (isClosed) {
      this.close();
    }
  }

  @Emit()
  close() {
    if (this.closeRoute) {
      this.$router.safeBack(this.closeRoute);
    }
  }

  @Emit()
  back() {
    if (this.closeRoute) {
      this.$router.safeBack(this.closeRoute);
    }
  }
}
