
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Menu } from '@gsk-tech/gsk-menu/gsk-menu';
import { MenuOption } from '@/components/gsk-components/menu/menu.types';

@Component
export default class IconMenu extends Vue {
  @Prop({ type: Array, required: true }) options!: MenuOption[];
  @Prop({ type: String, default: 'more_horizontal' }) icon!: string;
  @Prop({ type: Boolean }) mini!: boolean;
  @Prop({ type: Boolean }) disabled!: boolean;
  @Prop({ type: Boolean }) fab!: boolean;

  $refs!: {
    menu: Menu;
    menuAnchor: Element;
  };

  showMenu(): void {
    const menu = this.$refs.menu;
    const anchor = this.$refs.menuAnchor;
    menu.setAnchorCorner(menu.Corner.BOTTOM_LEFT);
    menu.setAnchorElement(anchor);
    menu.setAnchorMargin({ bottom: 16 });
    menu.open = !menu.open;
  }

  handleClick(opt: MenuOption): void {
    this.$emit('click', opt);
    if ('handler' in opt) {
      opt.handler?.(opt.key, opt.extra);
    }
  }
}
